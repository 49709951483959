@import '../../assets/styles/mixins';

.header {
  @include flex(row, flex-end, center, wrap);
  margin-bottom: 20px;
  .newUserButton {
    width: max-content;
    padding: 6px 10px;
  }
}

.groupContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.9rem;
    padding: 2px;
  }

  .tBodyCellClass {
    font-size: 0.85rem;
    padding: 6px 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 10px;
  }
}

.tableContainer {
  max-height: calc(100vh - 220px);
}