@import './src/assets/styles/mixins.scss';

.modal {
  padding: 40px 60px 50px;
  @include flex(column, space-between, center, nowrap);
  .modal__title {
    margin-bottom: 25px;
  }
  .modal__form {
    max-width: 400px;
    @include flex(column, space-between, center, nowrap);
    gap: 20px;
    .modal__form__container {
      width: 400px;
      @include flex(row, space-between, center, nowrap);
      gap: 10px;
      .modal__form__select {
        width: 300px;
      }
      .btnRoot {
        width: 100px;
        height: 56px;
      }
    }
    .modal__form__select {
      width: 400px;
      .selectLabel {
        margin-top: -14px;
      }
    }
  }
}

.tooltipPairs {
  font-size: 16px;
  padding: 4px;
  margin: 4px 0;
  line-height: 1em;
  overflow-x: auto;
  max-height: 700px;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
