@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 350px;
  max-width: 500px;
  border-radius: 10px;
  border: 1px solid rgba($grey, 0.5);
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  padding: 50px 50px 60px;

  .titleWrapper {
    position: relative;
    padding-bottom: 60px;
  }

  .title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: $elephant;
  }

  .requestError {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    color: $errorRed;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.03333em;
    white-space: nowrap;
  }

  .input {
    margin-bottom: 35px;
    position: relative;

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(115%);
    }
  }

  .btnRoot {
    padding: 8px 15px;
  }
}

.tfa_qr_code {
  width: 350px;
  height: 350px;
}

.tfa_input {
  margin-bottom: 35px !important;
  margin-top: 20px !important;
}

.modalRequestError {
  color: $errorRed;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  white-space: nowrap;
}

.modalTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: $elephant;
}

.modalSubTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: $elephant;
  margin-bottom: 10px;
}
