.menuWrapperOpen {
  + div {
    padding-left: calc(240px + 30px);
  }
}

.menuWrapperClosed {
  + div {
    padding-left: calc(74px + 30px);
  }
}