@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-top: 30px;
  padding-right: 30px;
}
