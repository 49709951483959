@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.formControl {
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  p {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(115%);
  }
}

.fullHeightTextField {
  margin: 0;

  input {
    height: 38px;
  }
}

.menu {
  width: 100%;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
  border: 1px solid $lightGrey;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.menuItem {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: $alabaster;
  }
}

.inputValid {
  .labelValid {
    color: $green;
  }

  fieldset {
    border-color: rgba($green, 0.5);
  }
}
