@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.input {
  z-index: 13;
  p {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(115%);
  }
}

.options {
  position: absolute;
  width: 100%;
  top: 56px;
  overflow-y: auto;
  max-height: calc(26px*12);
  .option {
    z-index: 120;
    background-color: #c3e6ff;
    width: 100%;
    height: 26px;
    &:hover {
      background-color: rgb(94, 185, 255);
    }
  }
}

.inputValid {
  label {
    color: $green;
  }

  fieldset {
    border-color: rgba($green, 0.5);
  }
}
