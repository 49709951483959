@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 60px 50px 40px;

  @include flex(row, center, flex-start, nowrap);

  .formWrapper {
    max-width: 550px;
  }
}

.loaderWrapper {
  position: absolute;
  top: 35%;
  left: 44%;
  transform: translate(-50%, -50%);
}

.modalContentInner {
  padding-right: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }

  .customInputClass {
    margin-bottom: 25px;

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
}
