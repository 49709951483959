@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.contentWrapper {
  max-width: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    min-width: 70%;
  }

  @media screen and (min-width: 992px) {
    min-width: 450px;
  }

  .titleWrapper {
    position: relative;
  }

  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .label {
    font-size: 20px;
    padding-bottom: 30px;
    margin-bottom: 16px;
  }

  .error {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
