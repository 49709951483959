@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.loaderWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @include flex(row, center, center, nowrap);

  span,
  p {
    transform: translateY(26px);
  }

  .errorMsg {
    color: $lightRed;
  }
}
