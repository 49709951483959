@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.limitsForm {
  padding: 24px;
  @include flex(column, space-between, center, nowrap);
  .formControl {
    width: 300px;
    margin: 12px 0;
  }
  .btnBlockWrapper {
    max-width: 550px;
    margin: 0 auto;
  }
  .flexed {
    max-width: 300px;
    width: 100%;
    padding: 6px;
    @include flex(column, flex-start, flex-start, wrap);
    .NumericFiled {
      width: 100%;
      margin: 9px 0 12px;
    }
  }
}