@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 550px;

  .selectRoot {
    margin-bottom: 30px;
  }

  .input {
    margin-bottom: 30px;
  }

  .listClass {
    max-height: 256px;

    .listItem {
      padding: 10px 16px;
    }
  }

  .pairsSelectBlock {
    margin-bottom: 20px;
  }

  .form {
    position: relative;

    .error {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 75%);
      white-space: nowrap;
    }
  }
}
