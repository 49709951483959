@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.formControl {
  width: 100%;
  margin-bottom: 30px;

  p {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(115%);
  }
}

.inputValid {
  .labelValid {
    color: $green;
  }

  fieldset {
    border-color: rgba($green, 0.5);
  }
}
