@import './src/assets/styles/mixins.scss';

.signalsContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.9rem;
    padding: 2px 8px;
  }

  .tBodyCellClass {
    font-size: 0.85rem;
    padding: 0 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 6px;
  }
}

.tableContainer {
  max-height: calc(100vh - 220px);
}

.btns {
  @include flex(row, flex-end, center, nowrap);
  gap: 12px;
  .btnHide {
    padding: 6px;
    width: 160px;
    margin-bottom: 8px;
  }
}
