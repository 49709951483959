@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.rootWrapper {
  width: 100%;

  .listInnerWrapper {
    @include flex(column, flex-start, center, nowrap);

    .loaderWrapper,
    .error {
      margin: 25px 0;
    }
  }

  .listItemRoot {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background-color: $white;
    padding: 30px;

    @include flex(row, space-between, center, nowrap);

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  .btnRoot {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .listItemInfoWrapper {
    max-width: 100%;
    margin-right: 50px;

    .title {
      text-transform: capitalize;
      font-size: 20px;
      margin-bottom: 6px;
    }

    .text {
      text-transform: capitalize;
      font-size: 18px;
      margin-bottom: 12px;
    }

    .time {
      font-size: 16px;
    }
  }
}
