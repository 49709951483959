@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.privilegePicker {
  @include flex(row, center, flex-start, nowrap);
  position: relative;
  .select {
    max-width: 100px;
    max-height: 30px;
  }
  .limitIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
