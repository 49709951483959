@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.title {
  margin-bottom: 90px;
}

.formWrapper {
  max-width: 450px;
  margin: 0 auto;
}

.form {
  .input {
    margin-bottom: 35px;
    position: relative;

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(115%);
    }
  }

  .inputValid {
    label {
      color: $green;
    }

    fieldset {
      border-color: rgba($green, 0.5);
    }
  }

  .btnBlockWrapper {
    @include flex(row, space-between, center, nowrap);

    button {
      max-width: 45%;
    }
  }

  .btnRoot {
    padding: 8px 15px;
  }

  .btnSavePrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}

.errorTitle {
  color: $lightRed;
}
