@import './src/assets/styles/mixins.scss';

.addedInfo {
  @include flex(row, center, center, nowrap);
  position: absolute;
  bottom: -42px;
  left: 0;
  gap: 6px;
  .dataItem {
    background-color: #38e6ff;
    border-radius: 8px;
    border: 1px solid #38e6ff;
    padding: 4px 12px 2px;
    span {
      font-size: 16px;
    }
  }
}