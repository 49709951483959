@import './src/assets/styles/mixins.scss';

.formWrapper {
  padding: 40px 60px 50px;
  @include flex(column, space-between, center, nowrap);
  .title {
    margin-bottom: 25px;
  }

  .input {
    margin-bottom: 40px;
  }
  .form {
    max-width: 400px;
    .select {
      width: 100%;
      .selectLabel {
        margin-top: -14px;
      }
    }
  }

}