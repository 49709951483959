@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(row, space-around, flex-start, nowrap);

  padding: 20px 100px 100px;

  @media screen and (max-width: 991px) {
    @include flex(column, flex-start, center, nowrap);
  }
}

.column {
  @include flex(column, flex-start, flex-start, nowrap);
}
