@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  min-width: 320px;
  margin-bottom: 50px;

  @media screen and (min-width: 1200px) {
    width: 500px;
  }

  .titleWrapper {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 10px;

    .reqError {
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
    }
  }
}

.tgNotificationForm {
  .input {
    margin-bottom: 35px;
    position: relative;

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(115%);
    }

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  .inputValid {
    label {
      color: $green;
    }

    fieldset {
      border-color: rgba($green, 0.5);
    }
  }
}
