@import './src/assets/styles/mixins.scss';

.formWrapper {
  padding: 40px 60px 50px;
  @include flex(column, space-between, center, nowrap);
  .title {
    margin-bottom: 25px;
  }

  .input {
    margin-bottom: 40px;
  }


  .form > div:first-child {
    @include flex(column, center, center, nowrap);
    gap: 25px;
  }
}