$white: #fff;
$alabaster: #f7f7f7;
$snowWhite: #f4f4f4;
$silkGrey: #f4f3f3;
$lightGrey: #e4e5ea;
$mercury: #e8e8e8;
$gallery: #eaeaea;
$scrollTrackWhite: #f2f3f6;
$scrollThumbGrey: #d5d5d5;
$softWhite: #e5e5e5;
$grey: #c4c4c4;
$darkGrey: rgba(0, 0, 0, 0.54);
$cloudGrey: #939393;
$softGrey: #9dafbd;
$disabledGrey: #979797;
$woodenGrey: #8a7c5a;
$woodenGreyLight: #8a9c5a;
$doveGray: #666;
$stoneBlack: #424141;
$elephant: #0f3249;
$black42: #0f1942;
$titleBlack: #111a3f;
$black: #000;

$green: #4caf50;
$lightBlue: #46cce1;
$cerulean: #00a7cf;
$blue: #1976d2;
$turtleGreen: #077186;
$turtleGreen2: #177d84;
$yellowSand: #e4ba89;
$yellow: #f8d442;
$coral: #f47853;
$red: #f00;
$muiRed: #f50057;
$darkRed: #d80027;
$lightRed: #e0391c;
$errorRed: #f44336;

$timeFast: 0.15s;
$timeMedium: 0.3s;

$breakpoints: (
  'small': 1012px,
  'medium': 1261px,
  'large': 1606px
);
