@import './src/assets/styles/mixins.scss';

.signalsContent {
  min-width: 430px;
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.9rem;
    padding: 2px 8px;
  }

  .tBodyCellClass {
    font-size: 0.85rem;
    padding: 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 6px;
  }
}

.actionButton {
  margin-right: 6px;
}

.tableContainer {
  max-height: calc(100vh - 240px);
}

.adminContainer {
  position: relative;
  .admin {
    @include flex(row, space-between, center, nowrap);
    .flexed {
      @include flex(row, space-between, center, nowrap);
      gap: 14px;
      .filterButton {
        width: 180px;
      }
    }
    .adminFilter {
      @include flex(row, flex-start, center, nowrap);
      .buttons {
        display: flex;
        gap: 6px;
        .btn {
          height: 40px;
        }
      }
      .filterFormControl {
        margin: 0 12px 12px 6px;
        height: 55px;
      }

      .filterFlexed {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: max-content;
        margin: 0 12px;
        padding: 0 1px;
        & > span:last-child {
          margin-right: 12px;
        }
        &:hover {
          border: 1px solid #cccccc;
          background: #f6f6f6;
          border-radius: 4px;
          padding: 0;
        }
        .filterButton {
          width: 180px;
        }
      }
    }
  }
}
