@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.signalsContent {
  .titleWrapper {
    position: relative;
    @include flex(row, space-between, flex-start, nowrap);
    .btnRoot {
      min-width: 250px;
    }
  }
  .administration {
    @include flex(row, space-between, flex-start, nowrap);
    .table {
      padding-right: 30px;
    }
  }
}


.title {
  margin-bottom: 30px;
}

.navTabWrapper {
  margin-bottom: 12px;
}
