@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.exchangeAccFormWrapper {
  max-width: 100%;
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 650px;
  }

  .titleWrapper {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 15px;

    .reqError {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}

.exchangeAccForm {
  .input {
    margin-bottom: 35px;
    position: relative;

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(115%);
    }
  }

  .inputValid {
    label {
      color: $green;
    }

    fieldset {
      border-color: rgba($green, 0.5);
    }
  }
}
