.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.ul-reset {
  @include ulReset();
}

.default-title {
  font-size: 24px;
  font-weight: 500;
  color: $darkGrey;
}

.default-text {
  font-size: 20px;
  font-weight: 500;
  color: $black42;
}

.errorText {
  color: $errorRed;
  font-size: 16px;
  font-weight: 500;
}
