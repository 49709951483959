@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  position: relative;
  height: calc(100vh - 60px);
  @include flex(column, space-between, center, nowrap);
  .input {
    max-width: 300px;
    align-self: center;
  }
  .columns {
    @include flex(row, center, center, wrap);
    max-height: calc(100vh - 60px - 100px);
    overflow-x: auto;
    max-width: 1000px;

    & > div {
      position: relative;
      min-width: 200px;
      margin: 24px 12px;
      .icon {
        position: absolute;
        z-index: 1;
        top: -11px;
        right: -11px;
        transform: scale(1.2);
        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .btnBlockWrapper {
    margin-top: 12px;
    max-width: 600px;
  }
}
