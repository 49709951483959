@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  width: 100%;
  height: 100%;
  padding-top: 80px;

  @include flex(row, center, flex-start, nowrap);

  .formWrapper {
    max-width: 550px;

    .titleWrapper {
      position: relative;
      margin-bottom: 40px;
    }

    .error {
      position: absolute;
      bottom: -90%;
      left: 0;
      white-space: nowrap;
    }

    .selectRoot {
      margin-bottom: 30px;
    }

    .input {
      margin-bottom: 30px;
    }
  }
}

.modalContent {
  .modalTitle {
    text-align: center;
    font-size: 22px;
    margin-bottom: 40px;

    span {
      display: block;
    }
  }
}
