@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.modalWrapper {
  @include flex(column, center, center, nowrap);

  .modalText {
    text-align: center;
    font-size: 18px;
    margin-bottom: 25px;
    opacity: 0.5;
  }
}

.actionWrapper {
  @include flex(column, center, center, nowrap);

  margin-left: 50px;

  .actionText {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    opacity: 0.5;
  }
}

.content {
  .header {
    @include flex(row, space-between, flex-start, nowrap);
    .btnsContainer {
      @include flex(row, space-between, flex-start, nowrap);

      .hideBtn {
        margin-right: 10px;
        min-width: 200px;
      }
      .btnRoot {
        min-width: max-content;
      }
    }
  }
  .btnsContainer {
    @include flex(row, flex-end, flex-start, nowrap);
    gap: 10px;
    .btnRoot {
      width: 110px;
    }
  }

  .filters {
    margin-bottom: 20px;
    margin-top: 20px;
    .filtersInputs {
      @include flex(row, space-between, center, nowrap);
      gap: 12px;
      & > div {
        width: 120px;
        @media screen and (min-width: 1090px) {
          width: 180px;
        }
      }
      .filterLabel {
        margin-top: -10px;
      }
    }
  }

  .collapsibleContent {
    position: relative;
    max-width: 200px;

    .collapseBtn {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(25px, -50%);
    }
  }

  .addsWrapper {
    max-width: 180px;

    @include flex(row, center, center, wrap);
  }

  .statusDefault {
    color: $coral;
  }

  .statusInactive {
    color: $red;
  }

  .statusActive {
    color: $green;
  }

  .btnDraft {
    color: $coral;
    border: 1px solid rgba($coral, 0.5);

    &:hover {
      border: 1px solid rgba($coral, 0.5);
      background-color: rgba($coral, 0.04);
    }
  }

  .btnPrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}
