@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.7);
  }

  .root {
    width: 100%;
  }

  .tableContainer {
    position: relative;
    min-height: 200px;
    overflow: auto;
    width: 100%;
  }
}

.tHeadCell {
  white-space: nowrap;
}

.sortWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg:hover {
    cursor: pointer;
  }
}
