@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  max-width: 550px;
}

.form_wrapper {
  max-height: 50vh;
  padding-top: 8px;
  margin-bottom: 30px;
  padding-bottom: 4px;

  & > div:last-child {
    margin-bottom: 0;
  }
}

.form {
  position: relative;

  .selectRoot {
    margin-bottom: 30px;
  }

  .twoInputsWrapper {
    @include flex(row, space-between, center, nowrap);
    gap: 4px;

    .input {
      max-width: 100%;
    }
  }

  .threeInputsWrapper {
    @include flex(column, space-between, center, nowrap);
    margin-bottom: 35px;
    .range_settings {
      @include flex(row, space-around, center, nowrap);
      margin-bottom: 8px;
      width: 100%;
    }
  }

  .input {
    margin-bottom: 30px;
  }

  .error {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 75%);
    white-space: nowrap;
  }
}
