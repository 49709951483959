@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.rootWrapper {
  max-width: 100%;

  .listRootScrollable {
    padding-right: 8px;
  }
}

.listRoot {
  max-height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }
}

.listItemRoot {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: $white;
  padding: 16px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.listTitleRoot {
  font-size: 18px;
  font-weight: 500;
  color: $black42;

  &::first-letter {
    text-transform: uppercase;
  }
}

.editBtnRoot {
  margin-right: 1px;
}
