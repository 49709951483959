@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableWrapper {
  max-height: calc(100vh - 170px);
  .headerWrapper {
    height: 40px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .btnRoot {
      width: max-content;
      justify-self: center;
    }
    .navTabWrapper {
      justify-self: flex-end;
    }
  }
}

.signalsContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.75rem;
    padding: 8px 2px;
  }

  .tBodyCellClass {
    font-size: 0.75rem;
    padding: 8px 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 10px;
  }
}

.filterWrapper {
  @include flex(row, flex-start, center, wrap);

  padding-top: 14px;
  margin-bottom: 25px;

  .filterTitle {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $darkGrey;
  }

  .filterFormControl {
    min-width: 170px;
    max-width: 300px;
    margin-right: 20px;
  }

  .filterButton {
    width: 170px;
    margin-left: auto;
  }

  .filterLabel {
    margin-top: -14px;
  }
}

.tableWrapper {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.75rem;
    padding: 8px 4px;
  }

  .tBodyCellClass {
    font-size: 0.75rem;
    padding: 4px;
    overflow-x: auto;
    max-width: 800px;
  }
}

.formWrapper {
  padding: 40px 60px 50px;

  .title {
    margin-bottom: 25px;
  }

  .input {
    margin-bottom: 40px;
  }

  .form {
    position: relative;
  }
}

.statusDefault {
  color: $coral;
}

.statusInactive {
  color: $red;
}

.statusActive {
  color: $green;
}
