@import '../../../assets/styles/mixins';

.blockerContainer {
  padding: 40px 60px 50px;
  @include flex(column, space-between, center, nowrap);
  .form {
    margin-top: 10px;
    @include flex(column, center, center, nowrap);
    gap: 28px;
    width: 300px;
  }
}