@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableWrapper {
  @include respond-max('medium') {
    max-height: calc(100vh - 280px);
  }
  @include respond-max('small') {
    max-height: calc(100vh - 300px);
  }
  max-height: calc(100vh - 260px);

  .tHeadCellClass {
    white-space: normal;
    font-size: 0.9rem;
    padding: 8px 4px;
  }

  .tBodyCellClass {
    font-size: 0.75rem;
    padding: 4px;
    overflow-x: auto;
    max-width: 800px;
  }
}

.filterWrapper {
  @include flex(row, space-between, center, normal);
  margin-bottom: 25px;
  padding-top: 12px;
  width: 90%;
  & > div {
    @include flex(row, start, center, wrap);
  }

  .filterTitle {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $darkGrey;
  }

  .seselectFilter {
    margin-top: 10px;
  }

  .searchFilterWrapp {
    display: flex;
  }

  .filterFormControl {
    min-width: 60px;
    margin-right: 5px;

    @include respond('medium') {
      min-width: 100px;
      margin-right: 20px;
    }

    @include respond('large') {
      min-width: 170px;
      margin-right: 20px;
    }

    .dataFilter {
      max-width: 100px;

      @include respond('small') {
        max-width: 160px;
        margin-right: 20px;
      }
    }
  }

  .filterSignalFormControl {
    display: flex;
  }

  .filterButton {
    width: 170px;
    margin-left: auto;
  }

  .filterLabel {
    margin-top: -14px;
  }
}

.messageContent {
  overflow: auto;
  max-width: 100%;
  max-height: 100px;
}

.eventContent {
  word-wrap: break-word;
  max-width: 142px;
}

.pairContent {
  text-align: center;
}
