@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: fit-content;

  .tabWrapper {
    width: fit-content;
    background-color: $white;

    .tabIndicator {
      background-color: $blue;
    }

    .tabRoot {
      width: 160px;
    }

    .tabSelected {
      color: $blue;
    }
  }
}
