@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btnBlockWrapper {
  @include flex(row, space-between, center, nowrap);

  width: 100%;

  button {
    max-width: 45%;
  }

  .btnRoot {
    padding: 8px 15px;
  }

  .btnSavePrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}
