@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btnBlockWrapper {
  max-width: 550px;
  margin: 0 auto;
}

.centeredBtnBlockWrapper {
  @include flex(row, space-around, center, nowrap);

  width: 100%;

  button {
    max-width: 45%;
  }

  .btnRoot {
    padding: 8px 15px;
  }

  .btnSavePrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}

.form_wrapper {
  max-height: 60vh;
  padding-top: 8px;
  margin-bottom: 30px;

  & > div:last-child {
    margin-bottom: 0;
  }
}

.form {
  .fieldBlocksWrapper {
    @include flex(row, space-between, flex-start, nowrap);

    margin-bottom: 50px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .dynamicOrdersWrapper {
    margin-right: 56px;
  }

  .dynamicOrdersWrapper,
  .staticOrdersWrapper {
    max-width: 550px;
    min-width: 550px;
    width: 100%;
  }

  .input {
    margin-bottom: 25px;
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  .select {
    min-width: 50%;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .selectLabel {
    margin-top: -10px;
  }

  .entryOrdersWrapper {
    margin-bottom: 40px;
  }

  .enterOrderRow {
    margin-bottom: 30px;

    .orderTitle {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .orderFieldsWrapper {
      @include flex(row, space-between, flex-start, nowrap);
      gap: 4px
    }

    .orderFieldsInput {
      max-width: 100%;
    }

    .exitFieldInput {
      margin: 0 4px;
    }
  }
}
