@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.wrapper {
  min-width: 320px;
  margin-bottom: 50px;

  @media screen and (min-width: 1200px) {
    width: 500px;
  }

  .title {
    margin-bottom: 30px;
  }

  .listWrapper {
    min-height: 50px;
    width: 100%;
    margin-bottom: 24px;
  }

  .loaderWrapper {
    width: 100%;

    @include flex(row, center, center, nowrap);
  }
}
