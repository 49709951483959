@import '../../assets/styles/mixins';

.groupContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.65rem;
    padding: 2px;
  }

  .tBodyCellClass {
    font-size: 0.75rem;
    padding: 6px 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 10px;
  }
}

.tableContainer {
  max-height: calc(100vh - 160px);
  height: calc(100vh - 210px);
}

.groupsHeader {
  @include flex(row, flex-end, center, nowrap);
  margin-bottom: 12px;
  height: 40px;
  width: 100%;
  .addBtn {
    width: max-content;
    padding: 6px 10px;
  }
}