@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableContainer {
  max-height: calc(100vh - 260px);
  @media screen and (min-width: 976px) {
    max-height: calc(100vh - 240px);
  }
}

.tableContainerWithoutPagination {
  max-height: calc(100vh - 210px);
  @media screen and (min-width: 976px) {
    max-height: calc(100vh - 190px);
  }
}

.signalsContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.9rem;
    padding: 2px;
  }

  .tBodyCellClass {
    font-size: 0.75rem;
    padding: 2px 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 6px;
  }
}

.filterWrapper {
  @include flex(row, space-between, center, normal);
  margin-bottom: 25px;
  padding-top: 12px;
  width: 90%;
  & > div {
    @include flex(row, start, center, wrap);
  }
  .filterTitle {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $darkGrey;
  }

  .seselectFilter {
    margin-top: 2px;
  }

  .searchFilterWrapp {
    display: flex;
    @include respond-max('medium') {
      margin-bottom: 25px;
    }
  }

  .filterFormControl {
    min-width: 60px;
    margin-right: 5px;
    @include respond('medium') {
      min-width: 100px;
      margin-right: 20px;
    }
    @include respond('large') {
      min-width: 170px;
      margin-right: 20px;
    }
    .dataFilter {
      max-width: 100px;
      @include respond('small') {
        max-width: 160px;
        margin-right: 20px;
      }
    }
  }

  .filterButton {
    width: 100px;
    margin-right: 12px;
  }

  .filterLabel {
    margin-top: -14px;
  }
}

.frameSpacer {
  margin-top: 12px;
}

.tableWrapper {
  min-width: 757px;
  .menu {
    width: 100%;
    @include flex(row, space-between, center, wrap);
    .btnContainer {
      width: 10%;
      @include flex(row, space-between, center, wrap);
      margin-bottom: 25px;
      padding-top: 12px;
      .btn {
        width: 100px;
        margin-left: auto;
      }
    }
    .tHeadCellClass {
      white-space: normal;
      font-size: 0.75rem;
      padding: 8px 4px;
    }

    .tBodyCellClass {
      font-size: 0.75rem;
      padding: 4px;
      overflow-x: auto;
      max-width: 800px;
    }
  }
}

.blockerLink {
  color: #0879ff;
  &:hover {
    cursor: pointer;
  }
}

.opposite_dir {
  @include flex(row, space-around, center, wrap);
  gap: 10px;
  .blockerLink {
    color: #0879ff;
    &:hover {
      cursor: pointer;
    }
  }
}

.formWrapper {
  padding: 40px 60px 50px;

  .title {
    margin-bottom: 25px;
  }

  .input {
    margin-bottom: 40px;
  }

  .form {
    position: relative;
  }
}

.statusDefault {
  color: $coral;
}

.statusInactive {
  color: $red;
}

.statusActive {
  color: $green;
}
