@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.loaderWrapper {
  position: absolute;
  top: 35%;
  left: 44%;
  transform: translate(-50%, -50%);
}

.navWrapper {
  margin-bottom: 50px;
}

.tabsWrapper {
  width: auto;
}

.innerContentWrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 60px 80px;
}

.innerContentTables {
  padding: 0;
}

.summaryRoot {
  width: 100%;
}

.summaryContent {
  margin-bottom: 0;
}
