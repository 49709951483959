@import './src/assets/styles/mixins.scss';

.admin {
  width: 100%;
  margin-bottom: 16px;
  @include flex(row, space-between, center, nowrap);
  .text {
    font-size: 18px;
  }
  .link {
    color: #0879ff;
    &:hover {
      cursor: pointer;
    }
  }
}