.message {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 10;
  min-width: max-content;
  max-width: 400px;
  margin: 0 auto;
  & > p {
    max-width: 400px;
  }
}
