@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.row {
  width: 100%;
  margin-bottom: 16px;

  @include flex(row, space-between, center, nowrap);
}

.rowText {
  font-size: 18px;
}

.column {
  @include flex(column, flex-start, flex-start, nowrap);
}

.labelOnTop {
  margin-bottom: 4px;
}

.link {
  @include activeLink();
}
