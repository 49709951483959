@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableWrapper {
  margin-bottom: 40px;
}

.table {
  width: 100%;

  .tCell {
    font-size: 16px;
    white-space: nowrap;
  }
}
