@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.filterWrapper {
  @include flex(row, flex-start, center, wrap);

  padding-top: 14px;
  margin-bottom: 25px;

  .filterTitle {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $darkGrey;
  }

  .filterFormControl {
    min-width: 170px;
    max-width: 300px;
    margin-right: 20px;
  }

  .filterButton {
    width: 170px;
    margin-left: auto;
  }

  .filterLabel {
    margin-top: -14px;
  }
}