@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.navBlock {
  @include flex(row, space-between, center, nowrap);

  width: 100%;

  .error {
    margin: 0 auto;
  }

  .titleBlock {
    @include flex(row, flex-start, center, nowrap);

    .arrowBtn {
      margin-right: 15px;

      &:hover {
        path {
          fill: $blue;
        }
      }
    }

    .arrowBack {
      transform: rotate(180deg);

      path {
        transition: all $timeFast ease-in-out;
        fill: rgba(0, 0, 0, 0.54);
      }
    }

    .title {
      margin-right: 30px;
      white-space: nowrap;
    }

    .statusDefault {
      font-size: 18px;
      font-weight: 500;
      color: $coral;
    }

    .statusInactive {
      color: $red;
    }

    .statusActive {
      color: $green;
    }
  }

  .btnDraft {
    max-width: 200px;
    color: $coral;
    border: 1px solid rgba($coral, 0.5);

    &:hover {
      border: 1px solid rgba($coral, 0.5);
      background-color: rgba($coral, 0.04);
    }
  }

  .btnPrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}
