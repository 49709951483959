.tableWrapper {
  max-height: calc(100vh - 332px);
  .headerWrapper {
    height: 40px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .exportBtn {
      width: max-content;
      justify-self: center;
    }
    .navTabWrapper {
      justify-self: flex-end;
    }
  }
}

.signalsContent {
  .tHeadCellClass {
    white-space: normal;
    font-size: 0.8rem;
    padding: 2px;
  }

  .tBodyCellClass {
    font-size: 0.8rem;
    padding: 20px 4px;
  }

  .btnRoot {
    line-height: 1;
    padding: 6px;
  }
}

.tableContainer {
  max-height: calc(100vh - 240px);
}
