@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.rangeButton {
  margin-right: 15px !important;
}

.filterWrapper {
  width: 300px;
  padding: 10px;
  padding-top: 20px;

  hr {
    padding: 0 -10px;
    height: 1px;
    width: 100%;
    margin-bottom: 10px;
  }

  .filterTitle {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $darkGrey;
  }

  .filterFormControl {
    width: 100%;
    margin-bottom: 10px;
  }

  .filterButton {
    margin-bottom: 10px;
  }

  .filterLabel {
    margin-left: -14px;
  }
}
