@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  min-width: 320px;

  @include flex(column, space-between, flex-start, nowrap);

  @media screen and (min-width: 1200px) {
    width: 500px;
    min-height: 724px;
  }

  .title {
    margin-bottom: 30px;
  }

  .listWrapper {
    min-height: 50px;
    width: 100%;
    margin-bottom: 24px;
  }

  .noDataWrapper {
    position: relative;

    .reqError {
      position: absolute;
      bottom: -100%;
      left: 0;
      white-space: nowrap;
    }
  }

  .loaderWrapper {
    width: 100%;

    @include flex(row, center, center, nowrap);
  }

  .btnPrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }
}
