@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableWrapper {
  max-height: calc(100vh - 260px);
}
.contentWrapper {
  .filters {
    margin: 26px 0 4px;
    @include flex(row, space-between, center, nowrap);
    gap: 8px;
    .filtersInputs {
      @include flex(row, space-between, center, nowrap);
      gap: 12px;
      & > div {
        width: 120px;
        @media screen and (min-width: 1090px) {
          width: 180px;
        }
      }
      .filterLabel {
        margin-top: -10px;
      }
    }
    .filterButton {
      width: 120px;
      @media screen and (min-width: 1090px) {
        width: 180px;
      }
    }
    .depositInfo {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;
      & > div {
        font-size: 12px;
        height: 16px;
        @media screen and (min-width: 1090px) {
          font-size: 16px;
          height: 32px;
        }
      }
    }
  }

  .titleWrapper {
    @include flex(row, space-between, center, nowrap);

    margin-bottom: 15px;
    .btnsContainer {
      @include flex(row, space-between, center, nowrap);
      gap: 10px;
      .btnRoot {
        min-width: 130px;
      }
      .changeDeposit {
        min-width: fit-content;
        & > span {
          white-space: nowrap;
        }
      }
    }
  }

  .textHelper {
    margin-bottom: 20px;
  }

  .btnPrimary {
    color: $green;
    border: 1px solid rgba($green, 0.5);

    &:hover {
      border: 1px solid rgba($green, 0.5);
      background-color: rgba($green, 0.04);
    }
  }

  .collapsibleContent {
    position: relative;
    max-width: 200px;

    .collapseBtn {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(25px, -50%);
    }
  }

  .profileAddsWrapper {
    max-width: 180px;

    @include flex(row, center, center, wrap);
  }
}

.statusInactive {
  color: $red;
}

.statusActive {
  color: $green;
}

.statusStopping {
  color: $coral;
}

.statusDraft {
  color: $yellowSand;
}
