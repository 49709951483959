@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.title {
  margin-bottom: 30px;
}

.navTabWrapper {
  margin-bottom: 30px;
}
