@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.titleBlockWrapper {
  margin-bottom: 30px;

  .stepsTemplated {
    @include flex(row, space-between, center, nowrap);
    .steps {
      margin-bottom: 10px;
    }
  }
}
