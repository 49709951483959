@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.collapsibleRoot {
  .collapsibleCellRoot {
    vertical-align: baseline;
  }
  .stickiedBottom {
    position: sticky;
    bottom: 0;
    background: #f5f5f5;
    z-index: 9;
  }
  .stickiedLeft {
    position: sticky;
    left: 0;
    background: #ffffff;
    z-index: 9;
  }
  .stickiedSingleRight {
    position: sticky;
    right: 0;
    background: #ffffff;
    z-index: 9;
  }
  .stickiedRight {
    position: sticky;
    right: 47px;
    background: #f1f1f1;
    z-index: 9;
  }
  .rightStickySecond {
    position: sticky;
    right: 0;
    background: #f1f1f1;
    z-index: 9;
  }
  .rightStickyThird {
    position: sticky;
    right: 120px;
    background: #f1f1f1;
    z-index: 9;
  }
  .rightStickyThird2 {
    position: sticky;
    right: 60px;
    background: #f1f1f1;
    z-index: 9;
  }
  .rightStickyThird3 {
    position: sticky;
    right: 0;
    background: #f1f1f1;
    z-index: 9;
  }
  .collapsibleCellOpen {
    padding: 16px;
  }

  .collapseContentWrapper {
    max-width: 100%;

    @include flex(row, center, center, nowrap);
  }
}

.rowWithError {
  background: rgb(251,85,63);
  background: radial-gradient(circle, rgba(251,85,63,1) 0%, rgba(252,70,141,0.9725140056022409) 100%);
}

.over:hover {
  background: #f7f7ff;
}
