@import './src/assets/styles/mixins.scss';

.adminList {
  width: 100%;
  min-width: 270px;
  border: #c4c4c4 1px  solid;
  border-radius: 8px;
  padding: 16px 12px 6px;
  background-color: #fefefe;
  max-height: calc(100vh - 110px);
  overflow-x: auto;
  .header {
    height: 20px;
    @include flex(row, center, center, nowrap);
    margin-bottom: 16px;
  }
}