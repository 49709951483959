@import './src/assets/styles/mixins.scss';

.staticsPage {
  .headerWrapper {
    height: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .subHeader {
    height: 80px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px;
    .selectors {
      @include flex(row, flex-start, center, nowrap);
      gap: 8px;
      .filterFormControl {
        min-width: 110px;
        .dataFilter {
          max-width: 100px;
          @media screen and (min-width: 1012px) {
            max-width: 100px;
            margin-right: 20px;
          }
        }
        .filterLabel {
          margin-top: -14px;
        }
      }
      .filterFormControlCheckBox {
        margin: 0 14px;
        min-width: 40px;
        .filterLabelCheckBox {
          position: absolute;
          top: -20px;
          left: -3px;
        }
      }
      .disability {
        cursor: not-allowed;
      }
    }
    .actionBtns {
      @include flex(row, flex-start, center, nowrap);
      gap: 4px;
      .filterButton {
        width: max-content;
      }
    }
  }
  .graphWrapper {
    margin-top: 12px;
    padding-top: 20px;
    height: calc(100vh - 260px);
    .paperClass {
      display: flex;
    }
  }
  .graphWrapperIframe {
    @media (max-height: 850px) {
      height: calc(100vh - 440px);
    }
    height: calc(100vh - 620px);
    margin-bottom: 30px;
    .paperClass {
      display: flex;
    }
  }
}

.chatSwitcher {
  display: flex;
  position: absolute;
  top: -16px;
  .switcherBlock {
    background-color: #D9D9D9;
    width: 100px;
    border-radius: 0 45px 0 0;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 400;
    line-height: 11px;
    &:hover {
      cursor: pointer;
      color: #00a7cf;
    }
  }
  .active {
    color: red
  }
}
